html {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

div#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

div.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #FFC107;
  color: #3C3C3B;
  height: 80px;
}

div.header span {
  font-weight: 600;
  font-size: 1.2em;
}

div.header div.img-wrapper {
  height: 100%;
  min-width: 130px;
}

div.header img {
  height: 60px;
  margin: 10px;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  padding-top: 25px;
}

div.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  width: 600px;
  max-width: 90vw;
  background: #eee;
  color: #000;
  margin: 15px auto;
  padding: 15px;
  box-shadow: 0 0 5px 5px rgba(0,0,0,.1);
}

div.content {
  display: block;
  width: 100%;
}

div.qr-code-wrapper+div.qr-code-wrapper {
  margin-top: 15px !important;
}

div.qr-code {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: white;
}

h2 {
  font-size: 20px !important;
  font-weight: 600;
  padding: 0 0 10px;
  border-bottom: 1px solid #ccc;
  background: #eee;
  color: #000;
}

@media screen and (max-width: 992px) {
  span.desktop-only {
    display: none;
  }
}